/* src/styles/CertificateManagement.css */
.page-title {
    color: #2c3e50;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .certificate-table {
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .certificate-table thead {
    background-color: #f8f9fa;
  }
  
  .certificate-table th {
    font-weight: 600;
    color: #2c3e50;
    vertical-align: middle;
    padding: 12px;
  }
  
  .certificate-table td {
    vertical-align: middle;
    padding: 12px;
  }
  
  .status-badge {
    padding: 0.35em 0.65em;
    border-radius: 50rem;
    font-size: 0.85em;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    min-width: 80px;
  }
  
  .status-Active {
    background-color: #d1fae5;
    color: #065f46;
  }
  
  .status-Inactive {
    background-color: #fee2e2;
    color: #991b1b;
  }
  
  .status-Expired {
    background-color: #fef3c7;
    color: #92400e;
  }
  
  .status-Suspended {
    background-color: #f3f4f6;
    color: #1f2937;
  }
  
  .certificate-modal .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .certificate-modal .modal-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 1rem 1.5rem;
  }
  
  .certificate-modal .modal-header .close {
    color: white;
  }
  
  .certificate-modal .modal-body {
    padding: 1.5rem;
  }
  
  .certificate-modal .form-label {
    font-weight: 500;
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }
  
  .certificate-modal .form-control,
  .certificate-modal .form-select {
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    padding: 0.5rem 0.75rem;
    font-size: 0.95rem;
  }
  
  .certificate-modal .form-control:focus,
  .certificate-modal .form-select:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 0.2rem rgba(59, 130, 246, 0.25);
  }
  
  .certificate-modal .form-control:disabled {
    background-color: #f1f5f9;
    cursor: not-allowed;
  }
  
  .table-responsive {
    margin-bottom: 2rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 4px;
  }
  
  .alert {
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  textarea.form-control {
    resize: vertical;
    min-height: 100px;
  }
  
  .modal-footer {
    border-top: 1px solid #e2e8f0;
    padding: 1rem 1.5rem;
  }
  
  .btn-warning {
    color: #fff;
    background-color: #f59e0b;
    border-color: #f59e0b;
  }
  
  .btn-warning:hover {
    background-color: #d97706;
    border-color: #d97706;
    color: #fff;
  }
  
  .btn-danger {
    background-color: #ef4444;
    border-color: #ef4444;
  }
  
  .btn-danger:hover {
    background-color: #dc2626;
    border-color: #dc2626;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .page-title {
      font-size: 1.5rem;
    }
  
    .certificate-table {
      font-size: 0.9rem;
    }
  
    .status-badge {
      min-width: 60px;
      font-size: 0.8em;
    }
  
    .btn-sm {
      padding: 0.2rem 0.4rem;
      font-size: 0.8rem;
    }
  }