
.container {
    max-width: 85%;
    margin: auto;
  }
  .flexSB {
    display: flex;
    justify-content: space-between;
  }
  .icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.3);
    color: #1eb2a6;
    transition: 0.5s;
  }
  .icon:hover {
    cursor: pointer;
    background-color: #1eb2a6;
    color: #fff;
  }

.newletter {
    background-color: #1eb2a6;
    padding: 50px 0;
    color: #fff;
  }
  .newletter .right,
  .newletter .left {
    padding-right: 50px;
  }
  .newletter h1 {
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .newletter .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .newletter input {
    width: 100%;
    padding: 20px;
    border: none;
    outline: none;
    height: 30px;
  }
  .newletter i {
    padding: 12px;
    color: #1eb2a6;
    background-color: #fff;
  }
  footer {
    background-color: #eeeeee;
  }
  footer .container {
    display: grid;
    grid-template-columns: 3fr 2fr 2fr 4fr;
    grid-gap: 30px;
  }

  
  
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
  .flex {
    display: flex;
  }
  .grid2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
  .outline-btn {
    margin: 0;
    box-shadow: none;
    border: 2px solid #1eb2a6;
    width: 100%;
    transition: 0.5s;
  }
  .outline-btn:hover {
    background-color: #1eb2a6;
    color: #fff;
  }
  .grid3 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
  }
  .padding {
    padding: 80px 0;
  }
  .shadow {
    box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
    background-color: #fff;
  }

  .row-1,
  .row-2 {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    .grid2,
    .grid3,
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
    .back {
      background-position: center;
      padding-top: 50%;
      height: 80vh;
    }
  }
  @media screen and (max-width: 768px) {
    footer .container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  footer .logo span {
    color: #1eb2a6;
    font-size: 14px;
  }
  footer .logo p {
    color: grey;
    margin: 30px 0 15px 0;
  }
  footer .logo .icon {
    background-color: #1eb2a6;
    color: white;
    margin-right: 10px;
  }
  footer h3 {
    margin-bottom: 40px;
    font-weight: 500;
  }
  footer ul li {
    margin-bottom: 20px;
  }
  footer .link li::before {
    content: "⟶";
    color: #1eb2a6;
    margin-right: 5px;
  }
  footer .items {
    margin-bottom: 30px;
  }
  footer .items img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
    object-fit: cover;
  }
  footer .items span,
  footer .items i {
    font-size: 12px;
    color: #1eb2a6;
    margin-right: 5px;
    text-transform: capitalize;
  }
  footer h4 {
    font-weight: 400;
    margin-top: 5px;
  }
  footer .last ul li {
    display: flex;
    margin-bottom: 50px;
  }
  footer .last i {
    width: 60px;
    font-size: 20px;
    color: #1eb2a6;
  }
  .legal {
    text-align: center;
    padding: 50px 0;
    color: grey;
    background-color: #e9e9e9;
  }
  .legal p {
    font-size: 15px;
  }
  .legal i {
    color: #1eb2a6;
  }

  .footerLogoMain{
    width: 120px;
  }