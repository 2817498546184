
html {
    box-sizing: border-box;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  * {
    max-height: 1000000px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  body {
    color: #333;
    background: #fff;
    font: 16px/1.6 "Muli", sans-serif;
    min-width: 320px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
  }
  
  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Muli", sans-serif;
    font-weight: 400;
    /* margin: 0 0 20px; */
    color: inherit;
  }
  
  h1 {
    font-size: 46px;
  }
  
  h2 {
    font-size: 30px;
  }
  
  h3 {
    font-size: 22px;
  }
  
  h4 {
    font-size: 18px;
  }
  
  h5 {
    font-size: 17px;
  }
  
  h6 {
    font-size: 15px;
  }
  
  p {
    /* margin: 0 0 10px; */
    line-height: 24px;
    /* text-align: justify; */
  }
  
  a {
    color: green;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  a:hover,
  a:focus {
    text-decoration: none;
    color: #f41a1a;
  }
  
  ul,
  .teams-block .image .overlay,
  .contact-block .contact-info ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  #wrapper {
    overflow: hidden;
    width: 100%;
    position: relative;
  }
  
  .container-fluid {
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .content-block ul {
    margin: 0 0 15px;
  }
  
  .content-block ul li {
    position: relative;
    padding: 0 0 0 20px;
  }
  
  .content-block ul li:before {
    content: '\f101';
    font-family: 'fontawesome';
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
  }
  
  .content-block ul li a {
    color: #333;
  }
  
  .content-block ul li a:hover {
    color: green;
  }
  
  .btn.btn-primary {
    background: green;
    border-color: green;
    padding: 13px 25px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    text-transform: uppercase;
    transition: background 0.3s linear, color 0.3s linear;
    -webkit-transition: background 0.3s linear, color 0.3s linear;
  }
  
  .btn.btn-primary:focus,
  .btn.btn-primary:hover {
    background: #fff;
    border-color: green;
    color: green;
  }
  
  .btn.btn-primary .fas {
    margin: 0 0 0 6px;
    font-size: 15px;
  }
  

  i

  .title-holder {
    text-align: center;
    margin: 0 0 40px;
    
  }
  
  .title-holder h2 {
    margin: 0 0 5px;
    text-transform: uppercase;
  }
  
  .title-holder .subtitle {
    color: #999999;
  }
  
  .title-holder .subtitle:before {
    content: '-';
    margin: 0 5px 0 0;
  }
  
  .title-holder .subtitle:after {
    content: '-';
    margin: 0 0 0 5px;
  }

  /* about part */
  .about-text{
    text-align: left;
  }
  
  .socials {
    display: table;
    height: 100%;
    width: 100%;
  }
  
  .socials ul {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  
  .socials li {
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
  }
  
  .socials a {
    width: 35px;
    height: 35px;
    background: #fff;
    color: green;
    border-radius: 50%;
    display: block;
    text-align: center;
    padding: 2px;
    transition: background 0.3s linear, color 0.3s linear;
    -webkit-transition: background 0.3s linear, color 0.3s linear;
  }
  
  .socials a:hover {
    background: green;
    color: #fff;
  }
  
  .socials .fab {
    line-height: 2;
  }
  
  .go-top {
    width: 50px;
    height: 50px;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: green;
    border-radius: 3px;
    color: #fff;
    border: 0;
  }
  
  .go-top:before {
    width: 13px;
    height: 13px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    content: '';
    border-style: solid;
    border-color: #fff;
    border-width: 1px 1px 0 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3px 0 0 -6px;
  }
  
  .go-top:hover {
    background: #f41a1a;
  }
  
  

  .head {
    padding: 10px 0;
    line-height: 50px;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
  }

  .top-nav{
    z-index: 9;
    color: #fff;
  }


    
  
  #header {
    padding: 15px 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    background: #fff;
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.5s linear;
  }
  
  #header .navbar {
    padding: 0;
    justify-content: space-between;
    background: none !important;
  }
  
  #header .navbar-brand {
    color: green;
    text-transform: uppercase;
    font-weight: 600;
    height: auto;
    padding: 0;
    font-size: 22px;
    line-height: 1.6;
  }
  
  #header .navbar-collapse {
    flex-grow: 0;
  }
  
  #header .navbar-collapse .navbar-nav {
    font-size: 14px;
    line-height: 20px;
  }
  
  #header .navbar-collapse a:last-child {
    padding-right: 0;
  }
  
  #header .navbar-collapse a {
    color: #4d4d4d;
    position: relative;
    font-size: 16px;
  }
  
  #header .navbar-collapse a:hover,
  #header .navbar-collapse a.active {
    background: none;
    color: green;
  }
  
  #header .navbar-collapse a:hover:before,
  #header .navbar-collapse a.active:before {
    right: 7px;
    left: 8px;
    opacity: 1;
  }
  
  #header .navbar-collapse a:before {
    content: '';
    background: green;
    height: 1px;
    position: absolute;
    bottom: 7px;
    left: -20px;
    right: 100%;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    opacity: 0;
  }
  
  /* contact */

.contact-left{
  background-color: #05268d;
 
}
  .form-control {
    height: 36px;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    border-radius: 2px;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1);

}
.contactForm .form-control {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
}


.form-control:focus,.form-control:active {
    border-color: #01d28e !important;
}
.form-label {
    color: #000;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

/* .bi{
    font-size: 50px;
} */

/* contact ends */

  #footer {
    background: green;
    padding: 50px 0;
    color: #fff;
    text-align: center;
  }
  
  #footer .copyright {
    margin: 0 0 15px;
  }
  
  #footer .socials a:hover {
    background: #f53333;
  }
  
  .block {
    padding: 90px 0;
    border-bottom: 1px solid #e6e6e6;
  }
  
  .hero-block {
    margin: 125px 0 0;
  }
  
  .hero-block .carousel-item:before {
    content: '';
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .hero-block .carousel-item img {
    max-width: 100%;
  }
  
  .hero-block .carousel-caption {
    left: 25%;
    right: 25%;
    transform: translateY(50%);
    -webkit-transform: translateY(50%);
    bottom: 40%;
  }
  
  .hero-block p {
    margin: 0 0 40px;
  }
  
  .about-block .progress-block {
    margin: 0 0 25px;
  }
  
  .about-block .progress-block h4 {
    text-transform: uppercase;
    margin: 0 0 5px;
    font-size: 16px;
  }
  
  .about-block .progress-bar {
    background-color: green;
    -webkit-transition: width 0.3s linear;
    transition: width 0.3s linear;
  }

  /* choose component */
  #choose-section{
    margin: 60px 0;
  }

  .choose-image-side{
    margin-top: 55px;
    margin-left: 20px;
    box-shadow: -1px 10px 10px rgba(0, 0, 0, 0.3)
  }

  .feature-text {
    padding-left: 10px;
  }

  /* blog starts */
/* .blog-wrapper{
  background: url('../src/assets/images/b19.jpg');
  background-size: cover;
  width: 100%;
  height: 30vh;
  justify-content: center;
  background-position: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
} */


  .card:hover{
    box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253)!important;
  }

  .overflow{
    overflow: hidden;
  }

  .card-img-top{
    transform: scale(1);
    transition:transform 0.6s ease-in-out;
    
  }

  .card-img-top:hover{
    transform: scale(1.6);
  }

  
   .certificate-text{
      font-size: 16px;
      text-align: justify;
   }
  
  .contact-block {
    position: relative;
  }
  
  
  
  @media only screen and (min-width: 1025px) {
  
    a[href^=tel],
    a[href^=skype],
    a[href^=sms] {
      cursor: default;
      pointer-events: none;
    }
  }
  
  @media only screen and (max-width: 1023px) {
    body {
      font-size: 15px;
      line-height: 24px;
    }
  
    h1 {
      font-size: 34px;
      margin: 0 0 15px;
    }
  
    h2 {
      font-size: 26px;
      margin: 0 0 15px;
    }
  
    .btn.btn-primary {
      padding: 9px 35px;
    }
  
    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=search],
    textarea {
      padding: 5px 10px;
      font-size: 15px;
    }

 
    
  
     #header {
      padding: 10px 0;
    }
   
    #header .navbar-collapse .navbar-nav {
      float: none;
    }
  
    #header .navbar-collapse .nav-item {
      float: none;
      display: block;
      padding: 7px 0;
    }
  
    .hero-block .carousel-caption {
      left: 7%;
      right: 7%;
   
    }

    .caption-text{
      padding-left: 20px;
    }
  
    .hero-block .carousel-control {
      width: 5%;
    }
  
    .hero-block p {
      margin: 0 0 25px;
    }
  
    .works-block .portfoliolist>div .label {
      height: 50px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    h2 {
      font-size: 20px;
    }
  
    h3 {
      font-size: 20px;
    }
  
    .title-holder {
      margin: 0 0 20px;
    }
  
    .go-top {
      width: 40px;
      height: 40px;
    }
  
    .go-top:before {
      width: 11px;
      height: 11px;
    }
  
    .pagetitle {
      padding: 30px 0;
      margin: 56px 0 0;
    }
  
    #header .navbar-collapse .navbar-nav {
      margin: 0;
    }
  
    .block {
      padding: 40px 0 10px;
    }
  
    .hero-block .carousel-caption {
      display: none;
    }
  
    .hero-block .carousel-control {
      width: 15%;
    }
  
    .about-block .image {
      padding-bottom: 20px;
    }
  
    .about-block .features [class*="col-"] {
      padding-bottom: 30px;
    }
  
    .about-block .progress-block {
      padding-top: 5px;
    }
  
    .services-block {
      padding: 40px 0 25px;
    }
  
    .services-block .row {
      margin-bottom: 0;
    }
  
    .services-block .holder:hover {
      margin: 0;
    }
  
    
    .blog-block {
      padding: 40px 0 20px;
    }
  
    .blog-block [class*="col-"] {
      padding-bottom: 20px;
    }
  
    .blog-block .row {
      margin-bottom: 0;
    }
  
    
  }