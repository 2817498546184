/* Delete Modal Styles */
.delete-modal .modal-content {
  border: none;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  z-index: 100;
}

.delete-modal .modal-header {
  border-bottom: none;
}

.delete-modal .modal-footer {
  border-top: none;
}

.delete-modal .btn {
  border-radius: 20px;
  min-width: 100px;
  transition: all 0.3s ease;
}

.delete-modal .btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.delete-modal .modal-body i {
  animation: warning-shake 0.5s ease;
}

/* Warning Shake Animation */
@keyframes warning-shake {
  0%, 100% { 
    transform: translateX(0); 
  }
  25% { 
    transform: translateX(-5px); 
  }
  75% { 
    transform: translateX(5px); 
  }
}

/* General Styles */
.table img {
  transition: transform 0.3s ease;
}

.table img:hover {
  transform: scale(1.1);
}

.btn {
  transition: all 0.2s ease;
}

.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

/* Alert Styles */
.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  min-width: 300px;
  animation: slideIn 0.5s ease;
}

/* Slide In Animation */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Modal Transitions */
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
}

.modal.show .modal-dialog {
  transform: none;
}

/* Form Controls */
.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Image Preview */
.mt-2 img {
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Table Hover Effects */
.table tbody tr {
  transition: background-color 0.2s ease;
}

.table tbody tr:hover {
  background-color: rgba(0,0,0,0.02);
}

/* Button Loading State */
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}


.full-dashboard{
  margin-top: 100px;
}

.addModalCSS{
  margin-top: 100px;
}

.addModalCSSs{
  margin-top: 200px;
}