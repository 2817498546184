.TopMeanVerificationHeading {
  margin-top: 220px;
  max-width: 1280px;
  margin-bottom: 50px;
}

.meanVerificationHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.heading {
  border: 3px solid green;
  border-radius: 10px;
  width: 30%;
}
.heading-bottom {
  width: 60%;
  border: 3px solid green;
}

.searchHeading {
  background-color: green;
  margin: 10px;
  border-radius: 5px;
  font-size: 26px;
  text-align: center;
  color: white;
  padding: 25px;
}

.searchPara {
  padding: 10px;
}

.searchInputFil {
  padding: 0px 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.searchInputFilHeading {
  font-weight: bold;
  font-size: 13px;
}
.mainsearchInputButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchInputButton {
  width: 30%;
  margin-top: 10px;
  background-color: green;
  outline: none;
  color: white;
}

.heading-bottom {
  margin-top: -250px;
  text-align: center;
  border-radius: 10px;
}

.heading-bottom-result {
  background-color: green;
  margin: 20px;
  border-radius: 10px;
  color: white;
  padding: 10px;
}



.certificate-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.search-section {
  margin-bottom: 30px;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
}

.table-row {
  display: flex;
  border: 1px solid #ddd;
}

.label-cell {
  flex: 0 0 250px;
  padding: 12px 15px;
  background-color: #4CAF50;  /* Green background */
  color: white;
  font-weight: 500;
  border-right: 1px solid #ddd;
}

.value-cell {
  flex: 1;
  padding: 12px 15px;
  background-color: white;
}


/* ----------------- */
.no-result {
  padding: 20px;
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  color: #c62828;
  margin-top: 20px;
  border-radius: 4px;
  text-align: center;
}

.searchInput {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

.searchInput::placeholder {
  color: #999;
  font-size: 14px;
}

/* Rest of the existing styles remain the same */
.TopMeanVerificationHeading {
  position: relative;
  z-index: 1;
}

.meanVerificationHeading {
  background-color: #fff;
  padding: 20px;
}

.heading-bottom {
  margin-top: 20px;
  z-index: 2;
  position: relative;
  background-color: #fff;
}

.result-container {
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
}

.result-field {
  display: flex;
  border: 1px solid #ddd;
}

.field-label {
  flex: 0 0 250px;
  padding: 12px 15px;
  background-color: #4CAF50;
  color: white;
  font-weight: 500;
  border-right: 1px solid #ddd;
}

.field-value {
  flex: 1;
  padding: 12px 15px;
  background-color: white;
}

.result-field + .result-field {
  margin-top: -1px;
}


/* Add these new styles to your existing CSS */

.searching-indicator {
  text-align: center;
  padding: 40px 20px;
  color: #666;
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 0 auto 15px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #2e7d32;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-result {
  margin: 30px;
  padding: 30px;
  background: #fff;
  border: 2px solid #ffcdd2;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.no-result-icon {
  font-size: 48px;
  margin-bottom: 15px;
}

.no-result-title {
  color: #d32f2f;
  font-size: 24px;
  margin-bottom: 15px;
}

.no-result-message {
  color: #666;
  margin-bottom: 10px;
}

.no-result-suggestion {
  color: #666;
  font-style: italic;
}

strong {
  color: #d32f2f;
  font-weight: 600;
}