.policyContainer {
    max-width: 1240px;          /* Set a max width for readability */
    margin: 170px auto;        /* Center the container */
    padding: 20px;            /* Add padding */
    background-color: #f9f9f9; /* Light background */
    border-radius: 8px;       /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.policyTitle {
    font-size: 28px;          /* Large title size */
    margin-bottom: 20px;      /* Space below the title */
    text-align: center;       /* Center the title */
    color: #333;              /* Dark color for contrast */
}

.policyImage {
    max-width: 100%;          /* Responsive image */
    height: auto;             /* Maintain aspect ratio */
    margin: 20px 0;          /* Space above and below the image */
    border-radius: 5px;      /* Optional: rounded corners */
}

.policyText {
    font-size: 16px;          /* Regular text size */
    line-height: 1.6;         /* Line height for readability */
    margin-bottom: 15px;      /* Space between paragraphs */
    color: #555;              /* Slightly lighter text color */
}
