.HeaderLogo {
  width: 150px;
}
.heading-address {
  color: black;
  width: 330px;
  font-size: normal;
  font-size: 16px;
}
.headAddress {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.headAdressIcon {
  margin-bottom: 20px;
}
.headerMainNav {
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerTitle {
  color: black;
  font-size: 20px;
}
.MainNavbar {
  font-size: 50px;
}

/* Training */

.TrainingHeading {
  margin-top: 200px;
  max-width: 1400px;
}

/* Accredation */
.AccredationHeading {
  margin-top: 200px;
  max-width: 1400px;
}
.headerImage {
  width: 150px;
}

.AboutContainer {
  margin-top: 200px;
}

.FooterLogo {
  width: 100px;
}

.searchHeadingSpan {
  font-weight: bold;
}

/* AccreditationsImages */

.AccreditationsImages {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin-top: 20px;
}

.AccreditationsImagesGlobal{
  width: 290px;
}

.AccreditationsHeader{
  text-align: center;
  margin-bottom: 100px;
  font-size: 40px;
}

.aboutContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}


cs-maring{
  margin-bottom: 100px;
}


