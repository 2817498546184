.hover-border {
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 10px;
    background-color: #f8f9fa;
  }
  
  .hover-border:hover {
    border: 2px solid #007bff;
    box-shadow: 0 4px 20px rgba(0, 123, 255, 0.2);
  }
  
  .card-body {
    padding: 20px;
  }
  


  .contact-banner {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
  }
  
  .contact-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .banner-overlay h1 {
    font-size: 3rem;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }

  .contact-form-wrapper {
    height: 50%; /* Set the height to 50% */
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
  }
  
  .contact-form {
    width: 100%;
    max-height: 100%; /* Ensure it doesn't exceed the container */
  }
  
  .contact-image-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contact-image-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .send-button {
    width: 100%;
    background-color: #333; /* Light black color */
    color: white;
    border: none;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .send-button:hover {
    background-color: #ff6666; /* Light red color */
    box-shadow: 0 4px 10px rgba(255, 102, 102, 0.3);
  }